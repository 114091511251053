<app-header menu="no">
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">{{ customer.First_Name }} {{
        customer.Last_Name }}</h1>
</app-header>
<div class="page-title-clear"></div>

<div class="page-content" id="tab-group-1">
    <div class="tab-controls card-style mb-4 tabs-small tabs-rounded letterSpacing"
        data-highlight="bg-primary-light-color" v-if="customer">
        <a href="#" class="font-600 btn-ripple" data-active data-bs-toggle="collapse"
            data-bs-target="#tab-1">Customer</a>
        <a href="#" class="font-600 btn-ripple" data-bs-toggle="collapse"
            data-bs-target="#tab-2">Orders({{customer.orders.length ? customer.orders.length : "0"}})</a>
    </div>


    <div class="card card-style bg-theme pb-0 mt-3 mx-4" v-if="customer">
        <div class="content my-2">

            <!------------- CUSTOMER PERSONAL DETAIL PART START ------------->

            <div data-bs-parent="#tab-group-1" class="collapse show" id="tab-1">
                <div class="customer-details mb-3 mx-2">
                    <div class="customer-detailIconContainer me-3">
                        <div class="customer-detailIconWrapper">
                            <i class="fas fa-envelope customer-detailIcon"></i>
                        </div>
                    </div>
                    <div class="customer-detailInfo">
                        <h5 class="font-600">
                            {{ customer.Email.length > 21 ? `${customer.Email.slice(0,21)}...` : customer.Email}}
                        </h5>
                    </div>
                </div>
                <div class="customer-details mb-3 mx-2">
                    <div class="customer-detailIconContainer me-3">
                        <div class="customer-detailIconWrapper">
                            <i class="fas fa-phone customer-detailIcon"></i>
                        </div>
                    </div>
                    <div class="customer-detailInfo">
                        <h5 class="font-600">{{ customer.Phone ? customer.Phone : "-"}}</h5>
                    </div>
                </div>
                <div class="customer-details mb-3 mx-2">
                    <div class="customer-detailIconContainer me-3">
                        <div class="customer-detailIconWrapper">
                            <i class="fas fa-search-location customer-detailIcon"></i>
                        </div>
                    </div>
                    <div class="customer-detailInfo">
                        <h5 class="font-600">{{customer.Lead_Source ? customer.Lead_Source :"-"}}</h5>
                    </div>
                </div>
                <div class="customer-details mb-3 mx-2">
                    <div class="customer-detailIconContainer me-3">
                        <div class="customer-detailIconWrapper">
                            <i class="fas fa-link customer-detailIcon"></i>
                        </div>
                    </div>
                    <div class="customer-detailInfo">
                        <h5 class="fw-bold">{{customer.Referral_Code ? customer.Referral_Code : "-"}}</h5>
                    </div>
                </div>
                <div class="customer-details mb-3 mx-2" v-if="customer.Referral_Code">
                    <div class="customer-detailIconContainer me-3">
                        <div class="customer-detailIconWrapper">
                            <i class="fas fa-user customer-detailIcon"></i>
                        </div>
                    </div>
                    <div class="customer-detailInfo">
                        <h5 class="fw-bold">
                            <router-link :to="{'name': 'partner-detail', params: {'id' : customer.partner.id}}">
                                {{ customer.partner.first_name +" "+ customer.partner.last_name}}
                            </router-link>
                        </h5>
                    </div>
                </div>
                <div class="customer-details mb-3 mx-2">
                    <div class="customer-detailIconContainer me-3">
                        <div class="customer-detailIconWrapper">
                            <i class="fas fa-clock customer-detailIcon"></i>
                        </div>
                    </div>
                    <div class="customer-detailInfo">
                        <h5>{{ localDateTime(customer.Created_Time)}}</h5>
                    </div>
                </div>
                <div class="customer-details mb-3 mx-2">
                    <div class="customer-detailIconContainer me-3">
                        <div class="customer-detailIconWrapper">
                            <i class="fas fa-location-arrow customer-detailIcon"></i>
                        </div>
                    </div>
                    <div class="customer-detailInfo">
                        <h5
                            v-if="customer.Mailing_Street!=null || customer.Mailing_City!=null || customer.Mailing_State!=null|| customer.Mailing_Country!=null">
                            <div class="col-12 letterSpacing">
                                {{ customer.Mailing_Street}},{{ customer.Mailing_City}},
                            </div>
                            <div class="col-12 letterSpacing">
                                {{ customer.Mailing_State}},{{
                                customer.Mailing_Country}}-{{ customer.Mailing_Zip}}
                            </div>
                        </h5>

                        <h5 v-else>-</h5>
                    </div>
                </div>
            </div>

            <!------------- CUSTOMER PERSONAL DETAIL PART END ------------->


            <!------------- CUSTOMER ORDER DETAIL PART START ------------->

            <div data-bs-parent="#tab-group-1" class="collapse" id="tab-2">

                <div v-for="(record, index) in customer.orders" :class="{'border-top': index}">
                    <div class="d-flex">
                        <div class="align-self-center">
                            <h2 class="text-secondary font-15 font-500 line-height-s mt-1 mb-1">
                                {{ record.crm_id }}
                            </h2>
                            <p class="font-12 line-height-s mt-3 mb-1">
                                <span class="font-600 orderStatusBagde"
                                    :class="[record.Status == 'Delivered' ? 'primary-light-text':'primary-alt-light-text']">{{
                                    record.Status }}</span>
                            </p>
                            <!-- <p class="font-12 line-height-s mt-1 mb-1" v-if="record.Paid_Via">
                                            Paid via: <span class="color-theme">{{ record.Paid_Via }}</span>
                                        </p> -->
                        </div>
                        <div class="ms-auto ps-3 align-items-end">
                            <p class="mb-0 text-end">
                                <span class="text-success font-500 font-15">{{record.$currency_symbol}} {{
                                    record.Grand_Total
                                    }}</span>
                            </p>
                            <p class="font-10 mb-0 mt-2 text-end" v-if="record.created_at">
                                <span class="">{{ localDate(record.created_at) }}</span>
                            </p>
                        </div>
                        <!-- <div class="d-flex ps-2 align-items-center">
                                <i class="fa fa-chevron-right color-highlight"></i>
                            </div> -->
                    </div>
                </div>
                <div class="my-3 text-center" v-if="!Object.keys(customer.orders).length">
                    <i class="fas fa-search font-20 text-secondary mb-3"></i>
                    <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
                        No Order Found
                    </p>
                </div>
            </div>

            <!------------- CUSTOMER ORDER DETAIL PART END ------------->

        </div>
    </div>
</div>
<app-footer />